import React, { Component } from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../Layouts/index";
import cover from "../../images/people-hero.png";
import team001 from "../../images/t1.jpg";
import team002 from "../../images/t2.jpg";
import team003 from "../../images/t3.jpg";
import team004 from "../../images/t4.jpg";
import team005 from "../../images/t5.jpg";
import team006 from "../../images/t6.jpg";
import Newsletter from "../../components/Newsletter";
// import { Link } from 'gatsby';
import { Link } from "gatsby-plugin-modal-routing";

// import { Link } from "gatsby-plugin-modal-routing"

// ReactModal.setAppElement('#___gatsby')
class People extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false
    };
  }
  // handleModalOpen = event => {
  //   // console.log('handleModalOpen: ', event);
  //   this.setState({ isModalOpen: true })
  // }

  // handleModalClose = event => {
  //   // console.log('handleModalOpen: ', event);
  //   this.setState({ isModalOpen: false })
  // }

  render() {
    return (
      <Layout bodyClass="page-people page">
        <SEO title="People" />
        <div className="cover-container cover-container-people">
          <div className="cover-img">
            <Img
              fluid={this.props.data.cover.childImageSharp.fluid}
              className="img-fluid"
              alt="Cover Image"
            />
          </div>
          <div className="cover-content">
            <div>
              <h1 className="hero-title">Our Team</h1>
              {/* <h4 className="hero-sub-title">Innovative. Passionate. Dedicated</h4> */}
            </div>
          </div>
        </div>
        <div className="py-3 py-md-5 people-wrap">
          <div className="intro-people">
            <div className="container">
              <div className="row no-gutters bg-color">
                <div className="col-12 col-md-6">
                  <div className="team-img">
                    <img alt="About team" className="img-fluid" src={team001} />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="detail ">
                    <h3 className="title-3">Peter Colley</h3>
                    <h4 className="">Chairman</h4>
                    <p className="people-para pt-2">
                      Peter founded the QC Communications Group of companies in
                      1997 and held the positions of Managing Director and CEO
                      during his tenure until recently handing over control to
                      son Steve.
                    </p>
                    <Link to="/people/people-1/" asModal>
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="intro-people">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-12 col-md-6 order-md-2">
                  <div className="team-img">
                    <img alt="About team" className="img-fluid" src={team002} />
                  </div>
                </div>
                <div className="col-12 col-md-6 order-md-1">
                  <div className="detail ">
                    <h3 className="title-3">Anna Godfrey</h3>
                    <h4 className="">Director</h4>
                    <p className="people-para pt-2">
                      Anna has a reputation for getting ‘Innovation’ DONE! She
                      has infectious enthusiasm that inspires people into action
                      and she loves nothing more than creating amazing new ideas
                      that deliver transformational growth.
                    </p>
                    <Link to="/people/people-2/" asModal>
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="intro-people">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-12 col-md-6">
                  <div className="team-img">
                    <img alt="About team" className="img-fluid" src={team003} />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="detail ">
                    <h3 className="title-3">Dale Humphries</h3>
                    <h4 className="">Director</h4>
                    <p className="people-para pt-2">
                      Dale is a fourth-generation farmer still living and
                      working on part of the original family farm “Wattle Grove”
                      in Oberon.
                    </p>
                    <Link to="/people/people-3/" asModal>
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="intro-people">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-12 col-md-6 order-md-2">
                  <div className="team-img">
                    <img alt="About team" className="img-fluid" src={team005} />
                  </div>
                </div>
                <div className="col-12 col-md-6 order-md-1">
                  <div className="detail ">
                    <h3 className="title-3">Cameron Shepherd</h3>
                    <h4 className="">Director</h4>
                    <p className="people-para pt-2">
                      Cameron is a founding Director and member of Speckle Park
                      Group Ltd and comes from a background of banking, finance,
                      accounting, and education.
                    </p>
                    <Link to="/people/people-5/" asModal>
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="intro-people mb-7">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-12 col-md-6">
                  <div className="team-img">
                    <img alt="About team" className="img-fluid" src={team006} />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="detail ">
                    <h3 className="title-3">Greg Ebbeck</h3>
                    <h4 className="">Director</h4>
                    <p className="people-para pt-2">
                      A third-generation cattleman Greg has over 50 years hands
                      on experience breeding top class cattle on his Six Star
                      Speckle Park property in Bowral NSW.
                    </p>
                    <Link to="/people/people-6/" asModal>
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Newsletter />
      </Layout>
    );
  }
}

export default People;

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "people.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
